import React from 'react'
import {graphql} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Image from 'gatsby-image'

import * as CSSMixins from '../assets/css/mixins.module.css'

import Header from '../components/Header'

export default function ie6redirect({data}) {
  const minWidth = '992px'

  return (
    <BackgroundImage
      fluid={data.bgImage.sharp.fluid}
      backgroundColor={'var(--color-bg-main)'}
      fadeIn={false}
      className={`min-vh-100 ${CSSMixins.minWidth}`}
      style={{'--minWidth': minWidth}}>
      <Header />
      <div className={`container pb-5 ${CSSMixins.minWidth}`} style={{'--minWidth': minWidth}}>
        <h1>{data.content.h1}</h1>
        <div className="row text-white">
          {/* START Steps */}
          <div className="col-5 pr-0">
            {data.content.steps.map((p, idx) => {
              return (
                <div className="row mb-1">
                  <div className="col-2 pr-0 font-weight-bold">
                    {data.content.stepTemplate} {idx + 1}
                  </div>
                  <div className="col-8 pl-2" dangerouslySetInnerHTML={{__html: p}} />
                </div>
              )
            })}
            <p className={`font-weight-bold mt-4 ${CSSMixins.maxWidth}`} style={{'--maxWidth': '300px'}}>
              {data.content.conclusion}
            </p>
          </div>
          {/* END Steps */}
          <div className="col-5">
            <Image fluid={data.content.ie6SettingsImage.sharp.fluid} />
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export const query = graphql`
  query {
    bgImage: file(relativePath: {eq: "pageBg.jpg"}) {
      sharp: childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    content: ie6PageJson {
      h1
      conclusion
      stepTemplate
      steps
      ie6SettingsImage {
        sharp: childImageSharp {
          fluid(quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
